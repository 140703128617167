import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const TagGridStyles = styled.div`
  display: grid;
  --col-width: 300px;
  grid-template-columns: repeat(auto-fill, minmax(var(--col-width), 1fr));
  @media (max-width: 1199px) {
    --col-width: 200px;
  }
`

const TagPanelStyles = styled.div`
  .panel-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      color: #000;
    }
  }
`

function TagPanel({ tag }) {
  return (
    <TagPanelStyles>
      <Link className="panel-wrapper" to={`/tag/${tag.slug.current}`}>
        <h2>{tag.name}</h2>
      </Link>
    </TagPanelStyles>
  )
}

export default function TagList({ tags }) {
  return (
    <TagGridStyles>
      {tags.map(tag => (
        <TagPanel key={tag.id} tag={tag} />
      ))}
    </TagGridStyles>
  )
}
