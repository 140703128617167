import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'

import TagList from '../components/TagList'

export default function Home({ data, pageContext }) {
  const tags = data.tags.nodes
  return (
    <>
      <SEO title="All Tags" />
      <TagList tags={tags} />
    </>
  )
}

export const query = graphql`
  query {
    tags: allSanityTag {
      nodes {
        name
        id
        slug {
          current
        }
      }
    }
  }
`
